import React from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"
import { device } from "../../constants/breakpoints"
import { selectTrattamentoVideos } from "../../redux/trattamentoPageSlice"
import CustomButton from "../Atoms/Button"
import ModalButton from "../ModalButton/ModalButton"

import VideoCard from "./VideoCard"

const Container = styled.div`
  display: flex;
  margin-top: 20px;
  background: #fbfbfb;
  padding: 20px;
  flex-direction: column;
  @media ${device.tablet} {
    border-radius: 0px;
  }
`

const Center = styled.p`
  text-align: center;
  font-weight: 500;
  color: ${({ theme }) => theme.midblue};
  font-size: 18px;
  @media ${device.tablet} {
    font-size: 18px;
  }
`

const Title = styled.p`
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: -5px;
  color: ${({ theme }) => theme.midblue};
  font-size: 24px;
  @media ${device.tablet} {
    font-size: 20px;
  }
`

const VideoCol = () => {
  const videos = useSelector(selectTrattamentoVideos)
  return (
    <Container>
      <Center> Raccontaci la tua esperienza</Center>
      <ModalButton> Scrivi una recensione</ModalButton>
      <div style={{ height: 20 }} />
      <Title>I video</Title>
      {videos.map(v => (
        <VideoCard key={v.key_video} video={v} />
      ))}
    </Container>
  )
}

export default VideoCol
