import moment from "moment"
import React from "react"
import { Col, Row } from "react-bootstrap"
import styled from "styled-components"
import navigateToChirurgo from "../../functions/navigateToChirurgo"

import { StarsWithPeople } from "../Stars/Stars"
import ResponsivePlayer from "../VideoCol/ResponsivePlayer"

export const Container = styled.div`
  background: white;
  margin: 10px;
  margin-top: 20px;
  border-radius: 6px;
`
export const Inner = styled.div`
  margin: 5px;
  padding-bottom: 10px;
`

export const Title = styled.p`
  color: ${({ theme }) => theme.blackblue};
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0px;
`

export const Date = styled.p`
  margin-bottom: 0px;
  font-size: 14px;
  color: #868686;
`

export const SubTitle = styled.p`
  font-weight: 500;
  margin-bottom: 0px;
  color: ${({ theme }) => theme.blue};
`

const VideoCardHorizontal = ({ video }) => {
  return (
    <Container>
      <Row>
        <Col md={6} sm={12}>
          <ResponsivePlayer video={video.key_video} />
        </Col>
        <Col md={6} sm={12}>
          <Inner>
            <Title
              onClick={() =>
                navigateToChirurgo({
                  nome: video.chirurgo,
                  id_sys: video.id_chi,
                })
              }
            >
              {video.chirurgo}
            </Title>
            <StarsWithPeople style={{ marginTop: 5 }} rating={video.rating} />
            <SubTitle>{video.titolo}</SubTitle>
            <Date>{moment(video.data).format("DD.MM.YYYY")} </Date>
          </Inner>
        </Col>
      </Row>
    </Container>
  )
}

export default VideoCardHorizontal
