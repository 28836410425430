import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { device } from "../../constants/breakpoints"
import { fetchChirurghiFilterAsync } from "../../redux/filterSlice"
import { fetchCitiesAsync, selectCity } from "../../redux/searchSlice"
import MobileSelect, { MobileSelectCity } from "../Atoms/MobileSelect"
import AutoComplete from "../AutoComplete/AutoComplete"
import CheckBox from "../CheckBox/CheckBox"
import {
  NoDesktop,
  NoMobile,
} from "../ChirurghiSearch/styles/ChirurghiSearch.styled"
import Filters from "../Filters/Filters"
import IosSwitch from "../IosSwitch"

const CheckLabel = styled.p`
  font-size: 12px;
`

export default function FiltersTrattamentiContainer({
  handleClickDesktop,
  handleClick,
  openFilters,
  filterActive,
  finan,
  setFinan,
  city,
  setCity,
  checked,
  setChecked,
  handleSubmit,
}) {
  const cities = useSelector(selectCity)

  if (!cities?.length) {
    return null
  }
  return (
    <Filters>
      <Filters.FilterRowTitle>
        <Filters.FilterTitle>FILTRI DI RICERCA</Filters.FilterTitle>
        <NoMobile>
          <Filters.FilterButtonText onClick={handleClickDesktop} />
        </NoMobile>
        <NoDesktop>
          <Filters.FilterButtonText onClick={handleClick} open={openFilters}>
            {filterActive && !openFilters && <Filters.FilterIndicator />}
          </Filters.FilterButtonText>
        </NoDesktop>
      </Filters.FilterRowTitle>
      <Filters.FilterInner open={openFilters}>
        {/*   <Filters.FilterRow>
          <Filters.FilterText>Videoconsulto</Filters.FilterText>
          <IosSwitch checked={video} handleChange={() => setVideo(!video)} />
        </Filters.FilterRow>
        */}
        <Filters.FilterRow>
          <Filters.FilterText>Con finanziamento</Filters.FilterText>
          <IosSwitch checked={finan} handleChange={() => setFinan(!finan)} />
        </Filters.FilterRow>
        <NoMobile>
          <Filters.FilterTextLabel style={{ marginTop: 20 }}>
            Città/ Provincia
          </Filters.FilterTextLabel>

          <AutoComplete
            options={cities}
            getOptionLabel={option => option.comune}
            value={city}
            placeholder={"Seleziona Città"}
            setValue={setCity}
          />
        </NoMobile>
        <div style={{ marginTop: 20 }} />
        <b>Sesso</b>
        <Filters.FilterRowCheckBox style={{ marginTop: 5 }}>
          <CheckBox onClick={() => setChecked(0)}>
            <CheckLabel style={{ margin: 0, marginTop: -3 }}>Uomo</CheckLabel>
            <CheckBox.Input
              type="checkbox"
              checked={checked == 0 ? "checked" : false}
            />
            <CheckBox.CheckRound style={{ marginTop: 0 }}></CheckBox.CheckRound>
          </CheckBox>
          <CheckBox title={"filter"} onClick={() => setChecked(1)}>
            <CheckLabel style={{ margin: 0, marginTop: -3 }}>Donna</CheckLabel>
            <CheckBox.Input
              type="checkbox"
              checked={checked == 1 ? "checked" : false}
            />
            <CheckBox.CheckRound style={{ marginTop: 0 }}></CheckBox.CheckRound>
          </CheckBox>
        </Filters.FilterRowCheckBox>
        <Filters.FilterRowCheckBox style={{ marginTop: 10 }}>
          <CheckBox
            style={{ marginTop: -5 }}
            title={"filter"}
            onClick={() => setChecked(2)}
          >
            <p style={{ margin: 0, marginTop: -3, fontSize: 12 }}>Tutti</p>
            <CheckBox.Input
              type="checkbox"
              checked={checked == 2 ? "checked" : false}
            />
            <CheckBox.CheckRound style={{ marginTop: 0 }}></CheckBox.CheckRound>
          </CheckBox>
        </Filters.FilterRowCheckBox>
        <NoDesktop>
          <MobileSelectCity setCity={setCity} handleSubmit={handleSubmit} />
        </NoDesktop>
      </Filters.FilterInner>

      <NoMobile>
        <div style={{ textAlign: "center" }}>
          <Filters.FilterButton onClick={handleSubmit}>
            APPLICA FILTRI
          </Filters.FilterButton>
        </div>
      </NoMobile>
    </Filters>
  )
}
