import React from "react"
import styled from "styled-components"
import { Col, Container as BottContainer, Row } from "react-bootstrap"
import SuggestedCol from "../SuggestedCol/SuggestedCol"
import PrePostCard from "./PrePostCard"
import VideoCol from "../VideoCol/VideoCol"
import _ from "lodash"
import { useSelector } from "react-redux"
import { selectTrattamentoPrePost } from "../../redux/trattamentoPageSlice"
import { device } from "../../constants/breakpoints"

const Wrapper = styled.div`
  background: white;
  padding-bottom: 150px;
  @media ${device.tablet} {
    padding-bottom: 0px;
  }
`

const Container = styled(BottContainer)`
  margin-top: 20px;
  padding-left: 5%;
  padding-right: 5%;

  overflow: hidden;
  @media ${device.tablet} {
    padding: 0px;
  }
`
export default function PrePostContainer({ title }) {
  const preposts = Object.values(
    _.groupBy(useSelector(selectTrattamentoPrePost), "id_chi")
  )

  if (!preposts.length) {
    return null
  }
  return (
    <Wrapper>
      <Container fluid>
        <Row>
          <Col sm={12} md={8} lg={9} xxl={10}>
            {preposts.map(c => (
              <PrePostCard prepost={c} />
            ))}
          </Col>

          <Col sm={12} md={4} lg={3} xxl={2}>
            <VideoCol title={title} />
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}
