import React from "react"
import styled from "styled-components"
import { Col, Container as BottContainer, Row } from "react-bootstrap"
import ReviewsCol from "../ReviewsCol/ReviewsCol"
import VideoCardHorizontal from "./VideoCardHorizontal"
import { useSelector } from "react-redux"
import { selectTrattamentoVideos } from "../../redux/trattamentoPageSlice"
import StickyBox from "react-sticky-box/dist/esnext"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import { device } from "../../constants/breakpoints"

const Wrapper = styled.div`
  background: white;
  padding-bottom: 150px;
  @media ${device.tablet} {
    padding-bottom: 0px;
  }
`
const Container = styled.div`
  padding-top: 20px;
  padding-bottom: 150px;
  background: #fff;
  padding-left: 5%;
  padding-right: 5%;
  @media ${device.tablet} {
    padding-top: 5px;
    flex-direction: column;
  }
`
export default function VideoContainer() {
  const videos = useSelector(selectTrattamentoVideos)
  const { width } = useWindowDimensions()
  return (
    <Wrapper>
      <Container>
        <Row>
          <Col sm={12} md={8} lg={9} xxl={10}>
            {videos.map(v => (
              <VideoCardHorizontal key={v.key_video} video={v} />
            ))}
          </Col>
          <Col sm={12} md={4} lg={3} xxl={2}>
            {width < 767 ? (
              <ReviewsCol />
            ) : (
              <StickyBox offsetTop={100} offsetBottom={20}>
                <ReviewsCol />
              </StickyBox>
            )}
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}
