import React, { useState } from "react"
import styled from "styled-components"
import { Col, Container as BottContainer, Row as RowB } from "react-bootstrap"

import FiltersTrattamentiContainer from "./FiltersTrattamentiContainer"
import ExpandedCardsContainer from "../ExpandedCardsContainer/ExpandedCardsContainer"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchChirurghiFilterAsync,
  fetchMoreChirurghiAsync,
  selectChirurghiFilter,
} from "../../redux/filterSlice"
import StickyBox from "react-sticky-box/dist/esnext"
import { device } from "../../constants/breakpoints"
import { TrattamentoItemContainer } from "../../constants/global-styles"
import { fetchCitiesAsync } from "../../redux/searchSlice"

const Row = styled(RowB)`
  --bs-gutter-x: 0;
  max-width: 1440px;
  margin: auto;
`

export default function DottoriTrattamentoContainer({ id_trattamento }) {
  const chirurghi = useSelector(selectChirurghiFilter)

  const [city, setCity] = useState("")

  const [video, setVideo] = React.useState(null)
  const [finan, setFinan] = React.useState(null)

  const [checked, setChecked] = useState(null)
  const [openFilters, setOpenFilters] = useState(false)

  const [filterActive, setFilterActive] = useState(false)

  React.useEffect(() => {
    dispatch(fetchCitiesAsync())
    resetFiltri()
  }, [])

  const resetFiltri = () => {
    dispatch(
      fetchChirurghiFilterAsync({
        id_comune: city ? city.id_sys : null,
        sesso: null,
        id_trattamento,
        video_consulto: null,
        finanziamento: null,
      })
    )
  }
  const dispatch = useDispatch()

  const handleClickDesktop = () => {
    //delete filters
    setVideo(null)
    setFinan(null)
    setCity(null)
    setChecked(null)
    resetFiltri()
  }

  const handleClick = () => {
    if (openFilters) {
      //delete filters
      setVideo(null)
      setFinan(null)
      setCity(null)

      setChecked(null)
      setOpenFilters(false)
      setFilterActive(false)
      resetFiltri()
      return
    }
    setOpenFilters(true)
  }

  const handleSubmit = () => {
    dispatch(
      fetchChirurghiFilterAsync({
        id_comune: city ? city.id_sys : null,
        sesso:
          checked == null || checked == 2 ? null : checked == 0 ? "M" : "F",
        id_trattamento,
        video_consulto: video == null ? null : video ? 1 : 0,
        finanziamento: finan == null ? null : finan ? 1 : 1,
      })
    )
    setFilterActive(true)
    setOpenFilters(false)
  }

  const fetchMore = table_page => {
    dispatch(
      fetchMoreChirurghiAsync({
        id_comune: city ? city.id_sys : null,
        sesso:
          checked == null || checked == 2 ? null : checked == 0 ? "M" : "F",
        id_trattamento,
        video_consulto: video == null ? null : video ? 1 : 0,
        finanziamento: finan == null ? null : finan ? 1 : 0,
        table_page,
      })
    )
  }

  return (
    <TrattamentoItemContainer>
      <Row gx-0>
        <Col sm={12} md={5} lg={4} xl={3}>
          <StickyBox offsetTop={100} offsetBottom={20}>
            <FiltersTrattamentiContainer
              handleClick={handleClick}
              openFiltrers={openFilters}
              handleClickDesktop={handleClickDesktop}
              id_trattamento={id_trattamento}
              filterActive={filterActive}
              finan={finan}
              setFinan={setFinan}
              city={city}
              setCity={setCity}
              checked={checked}
              setChecked={setChecked}
              handleSubmit={handleSubmit}
            />
          </StickyBox>
        </Col>
        <Col sm={12} md={7} lg={8} xl={9}>
          <ExpandedCardsContainer fetchMore={fetchMore} chirurghi={chirurghi} />
        </Col>
      </Row>
    </TrattamentoItemContainer>
  )
}
