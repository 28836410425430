import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { Col, Container as BottContainer, Row } from "react-bootstrap"
import SuggestedCol from "../SuggestedCol/SuggestedCol"
import TrattamentoReviewsCard from "./ReviewsCard"
import { useDispatch, useSelector } from "react-redux"
import {
  selectPreviewEsperienza,
  selectTrattamentoPageChirurghi,
  selectTrattamentoReviews,
  setPreviewEsperienza,
} from "../../redux/trattamentoPageSlice"
import StickyBox from "react-sticky-box/dist/esnext"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import useChirurghiHome from "../../hooks/useChiurghiHome"
import { navigate } from "gatsby"
import { device } from "../../constants/breakpoints"
import { selectCurrentUser } from "../../redux/userSlice"
import { navigateToEsperienza } from "../../functions/navigateToEsperienza"

const Wrapper = styled.div`
  background: white;
  min-height: 700px;
  padding-bottom: 150px;
  @media ${device.tablet} {
    padding-bottom: 0px;
  }
`

export const Container = styled.div`
  padding-top: 20px;
  padding-bottom: 150px;
  background: white;
  padding-left: 5%;
  padding-right: 5%;
  @media ${device.tablet} {
    padding-top: 5px;
    flex-direction: column;
  }
`

const Touchable = styled.div`
  text-align: center;
  cursor: pointer;
  color: ${({ theme }) => theme.blue};
  font-weight: 500;
`

export default function TrattamentoReviewsContainer({
  title,
  esperienza,
  search,
}) {
  const reviews = useSelector(selectTrattamentoReviews)
  const { width } = useWindowDimensions()
  const doctors = useSelector(selectTrattamentoPageChirurghi)
  const user = useSelector(selectCurrentUser)
  console.log({esperienza})
  if (esperienza) {
    return (
      <Wrapper>
        <Container fluid>
          <Row gx-0>
            <Col style={{ paddingRight: "2%" }} sm={12} md={8} xl={9} xxl={10}>
              <TrattamentoReviewsCard
                deletable={user?.user_id === esperienza?.id_user.toString()}
                expanded
                esperienza={esperienza}
              />
            </Col>
            <Col sm={12} md={4} xl={3} xxl={2}>
              <SuggestedCol doctors={doctors} title={title} />
            </Col>
          </Row>
        </Container>
      </Wrapper>
    )
  }
  if (reviews.length >= 3)
    return (
      <Wrapper>
        <Container>
          <Row gx-0>
            <Col style={{ paddingRight: "2%" }} sm={12} md={8} xl={9} xxl={10}>
              {reviews.map(r => (
                <TrattamentoReviewsCard
                  goToEsperienza={() => navigateToEsperienza({ esperienza: r })}
                  esperienza={r}
                />
              ))}
            </Col>
            <Col sm={12} md={4} xl={3} xxl={2}>
              {width < 767 ? (
                <SuggestedCol doctors={doctors} title={title} />
              ) : (
                <StickyBox offsetTop={100} offsetBottom={20}>
                  <SuggestedCol doctors={doctors} title={title} />
                </StickyBox>
              )}
            </Col>
          </Row>
          <Row style={{ marginTop: 0 }} gx-0>
            <Col style={{ paddingRight: "2%" }} sm={12} md={8} xl={9} xxl={10}>
              {reviews.map(r => (
                <TrattamentoReviewsCard
                  goToEsperienza={() => navigateToEsperienza({ esperienza: r })}
                  esperienza={r}
                />
              ))}
            </Col>
            <Col sm={12} md={4} xl={3} xxl={2}>
              {width < 767 ? (
                <SuggestedCol doctors={doctors} title={title} />
              ) : (
                <StickyBox offsetTop={100} offsetBottom={20}>
                  <SuggestedCol doctors={doctors} title={title} />
                </StickyBox>
              )}
            </Col>
          </Row>
        </Container>
      </Wrapper>
    )

  return (
    <Wrapper>
      <Container>
        <Row gx-0>
          <Col style={{ paddingRight: "2%" }} sm={12} md={8} lg={9} xxl={10}>
            {reviews.map(r => (
              <TrattamentoReviewsCard
                goToEsperienza={() => navigateToEsperienza({ esperienza: r })}
                esperienza={r}
              />
            ))}
          </Col>
          <Col sm={12} md={4} lg={3} xxl={2}>
            {width < 767 ? (
              <SuggestedCol doctors={doctors} title={title} />
            ) : (
              <StickyBox offsetTop={100} offsetBottom={20}>
                <SuggestedCol doctors={doctors} title={title} />
              </StickyBox>
            )}
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}
