import React from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"
import { device } from "../../constants/breakpoints"
import { selectTrattamentoReviews } from "../../redux/trattamentoPageSlice"
import ModalButton from "../ModalButton/ModalButton"

import ReviewsCardSm from "./ReviewsCardSm"

const Container = styled.div`
  margin-top: 20px;
  border-radius: 12px;
  background: #fbfbfb;
  padding: 20px;
  flex-direction: column;
  @media ${device.tablet} {
    border-radius: 0px;
  }
`

const Center = styled.p`
  text-align: center;
  font-weight: 500;
  color: ${({ theme }) => theme.midblue};
  font-size: 18px;
  @media ${device.tablet} {
    font-size: 18px;
  }
`

export const Button = styled.div`
  color: white;
  background: ${({ theme }) => theme.blue};
  padding: 10px;
  text-align: center;
  width: 200px;
  border-radius: 8px;
  margin: auto;
`

const Title = styled.p`
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: -5px;
  color: ${({ theme }) => theme.blackblue};
  font-size: 24px;
  font-weight: 500;
  @media ${device.tablet} {
    font-size: 20px;
  }
`

const ReviewsCol = () => {
  const reviews = useSelector(selectTrattamentoReviews)
  return (
    <Container>
      <Center> Raccontaci la tua esperienza</Center>
      <ModalButton>Scrivi una recensione</ModalButton>
      <div style={{ height: 20 }} />
      <Title>Leggi le ultime esperienze</Title>
      {reviews.slice(0, 2).map(r => (
        <ReviewsCardSm {...r} rating={3} />
      ))}
    </Container>
  )
}

export default ReviewsCol
