import React, { useEffect, useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import TrattamentoHeroContainer from "../../containers/trattamenti/trattamento-hero"
import TrattamentoMenu from "../../components/TrattamentoMenu"
import TrattamentoInfoContainer from "../../components/TrattamentoInfoContainer/TrattamentoInfoContainer"
import PrePostContainer from "../../components/PrePostContainer/PrePostContainer"
import VideoContainer from "../../components/VideoContainer/VideoContainer"
import DottoriTrattamentoContainer from "../../components/TrattamentoDottoriContainer/DottoriTrattamentoContainer"
import TrattamentoCostiContainer from "../../components/TrattamentoCostiContainer/TrattamentoCostiContainer"
import TrattamenetoReviewsContainer from "../../components/ReviewsCard/ReviewsCard.styled"
import TrattamentoArtiContainer from "../../components/TrattamentoArtiContainer/TrattamentoArtiContainer"
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs"
import { useBreadcrumb } from "gatsby-plugin-breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchTrattamento,
  selectTrattamentoPage,
  selectTrattamentoPageLoading,
  selectTrattamentoPrePost,
  setPreviewArticle,
  setPreviewEsperienza,
} from "../../redux/trattamentoPageSlice"
import LoadingView from "../../components/LoadingView/LoadingView"
import withLocation from "../../utils/withLocation"

const TrattamentoPrePostPage = ({ location, search }) => {
  const { crumbs } = useBreadcrumb({
    location,
    crumbLabel: "pre-post",
    crumbSeparator: " / ",
  })
  console.log("ksfankueqjwvgbqigy2uwfty", crumbs)
  const loading = useSelector(selectTrattamentoPageLoading)
  const trattamento = useSelector(selectTrattamentoPage)
  const preposts = useSelector(selectTrattamentoPrePost)
  const [active, setActive] = useState(0)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchTrattamento(search.id))
  }, [])

  if (loading) {
    return <LoadingView />
  }
  return (
    <Layout>
      <SEO title="Trattamento" />
      <BreadCrumbs crumbs={crumbs} />
      <TrattamentoHeroContainer
        img={trattamento.foto_banner_url}
        titolo={trattamento.trattamento}
        body={trattamento.riassunto}
      />
      <TrattamentoMenu title={search.title} id={search.id} />
      <PrePostContainer preposts={preposts} />
    </Layout>
  )
}

export default withLocation(TrattamentoPrePostPage)
