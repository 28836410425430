import { auto } from "@popperjs/core"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import CustomButton from "../Atoms/Button"
import ButtonOverlay, { ButtonOverlayStyle } from "../Atoms/ButtonOverlay"
import { OnlyLocationText } from "../Atoms/LocationWithText"
import { StarsWithPeople } from "../Stars/Stars"
import PrePostCarousel from "./PrePostCarousel"
import { navigate } from "gatsby"
import { GET_CHIRURGO } from "../../constants/api"
import axios from "axios"
import navigateToChirurgo from "../../functions/navigateToChirurgo"
import { useDispatch } from "react-redux"
import { openModal } from "../../redux/layoutSlice"
import { device } from "../../constants/breakpoints"

const Container = styled.div`
  margin-bottom: 40px;
  margin-top: 20px;
  @media ${device.tablet} {
    text-align: center;
  }
`
const Title = styled.p`
  cursor: pointer;
  font-size: 22px;
  font-weight: 500;
  @media ${device.tablet} {
    font-size: 18px;
    margin-top: 25px;
  }
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: -5px;
  @media ${device.tablet} {
    justify-content: center;
    flex-direction: column;
    margin: auto;
    max-width: 180px;
    & > ${ButtonOverlayStyle} {
      color: ${({ theme }) => theme.lightblue};
      border: none;
      ::after {
        content: " chirurgo";
      }
    }
  }
`

const PrePostCard = ({ prepost, rating }) => {
  const [chirurgo, setChirurgo] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchChirurgo = async () => {
      const { data } = await axios.get(GET_CHIRURGO(prepost[0].id_chi))

      setChirurgo(data[0])
    }

    fetchChirurgo()
  }, [])

  const goToChirurgoAsync = () => {
    navigateToChirurgo(chirurgo)
  }

  return (
    <Container>
      <PrePostCarousel prepost={prepost} />
      <Title onClick={() => goToChirurgoAsync()}>{prepost[0].chirurgo}</Title>
      {!chirurgo ? null : (
        <>
          <StarsWithPeople
            title={"prepost"}
            rating={chirurgo.rating}
            people={chirurgo.reviews_count}
          />
          <OnlyLocationText locations={chirurgo.list_provincie} />
          <Buttons>
            <ButtonOverlay onClick={() => goToChirurgoAsync()}>
              Scheda
            </ButtonOverlay>
            <CustomButton
              onClick={() => dispatch(openModal(chirurgo))}
              style={{ marginLeft: 15 }}
            >
              Contatta
            </CustomButton>
          </Buttons>
        </>
      )}
    </Container>
  )
}

export default PrePostCard
